import {
  ERROR_MESSAGE_TIMEOUT,
  SUCCESS_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import { RouteLevelAssignmentInput } from '../graphqlGenerated/graphql';
import AssignmentHelper from '../util/AssignmentHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import RouteService from '../services/RouteService';
import RouteStore from '../stores/routeStore';
import assignmentStore from '../stores/assignmentStore';

export const routeUnassignmentOperationName = 'routeUnassignment';

export default class RouteUnassignmentController {
  static async startUnassignment(): Promise<void> {
    assignmentStore.clearConfirmationMessages();
    await RouteUnassignmentController.confirmUnassignment();
  }

  static async confirmUnassignment(): Promise<void> {
    OperationController.startOperation('Unassign transporter');
    const { siteCode } = AssignmentHelper.getCommonAssignmentRequestFields();
    const routeLevelAssignmentInput: RouteLevelAssignmentInput =
      AssignmentHelper.getRouteAssignmentInputForUnassignment();

    const assignmentResponse = await RouteService.unassignRouteFromTransporter(
      siteCode,
      routeLevelAssignmentInput
    );
    OperationController.completeOperation();

    // gather the results and determine if need to inform user of any failures
    const { errorMessage, successful, failed } = assignmentResponse;
    if (errorMessage || successful.length === 0) {
      const msg = `Un-assignment failed${
        errorMessage ? `: ${errorMessage}` : ''
      }`;
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, routeUnassignmentOperationName);
    } else {
      // Unselect the successful route id
      RouteStore.removeSelectedRouteId();
      const msg = 'Successful un-assignment';
      MessagesController.addSuccessMessage(msg, SUCCESS_MESSAGE_TIMEOUT);
      Log.info(msg, routeUnassignmentOperationName);
    }
  }
}
