import {
  ERROR_MESSAGE_TIMEOUT,
  SUCCESS_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import { RouteLevelAssignmentInput } from '../graphqlGenerated/graphql';
import AssignmentHelper from '../util/AssignmentHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import RouteService from '../services/RouteService';
import RouteStore from '../stores/routeStore';
import assignmentStore from '../stores/assignmentStore';

export const routeAssignmentOperationName = 'routeAssignment';

export default class RouteAssignmentController {
  static async startAssignment(): Promise<void> {
    assignmentStore.clearConfirmationMessages();
    await RouteAssignmentController.confirmAssignment();
  }

  static async confirmAssignment(): Promise<void> {
    OperationController.startOperation('Assigning transporter');
    const { selectedTransporterId, siteCode } =
      AssignmentHelper.getCommonAssignmentRequestFields();
    const routeLevelAssignmentInput: RouteLevelAssignmentInput =
      AssignmentHelper.getRouteAssignmentInputForAssignment();

    const assignmentResponse = await RouteService.assignRouteToTransporter(
      siteCode,
      routeLevelAssignmentInput,
      selectedTransporterId
    );
    OperationController.completeOperation();

    // gather the results and determine if need to inform user of any failures
    const { errorMessage, successful, failed } = assignmentResponse;
    if (errorMessage || successful.length === 0) {
      const msg = `Assignment failed${errorMessage ? `: ${errorMessage}` : ''}`;
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, routeAssignmentOperationName);
    } else {
      // Unselect the successful route id
      RouteStore.removeSelectedRouteId();
      const msg = 'Successful assignment';
      MessagesController.addSuccessMessage(msg, SUCCESS_MESSAGE_TIMEOUT);
      Log.info(msg, routeAssignmentOperationName);
    }
  }
}
